export const clusteringImages = [
  {
    image: [
      {
        imageName: "slider-item-desktop.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_24.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_12.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_17.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_2.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_28.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_6.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_23.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_9.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_20.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_1.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_29.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_3.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_25.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_8.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_22.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_32.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_33.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_7.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_21.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_10.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_19.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_13.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_16.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_11.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_18.webp",
        minBreakpoint: "320px",
      },
    ],
  },
  {
    image: [
      {
        imageName: "slider-item-desktop_4.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_26.webp",
        minBreakpoint: "320px",
      },
    ],
  },

  {
    image: [
      {
        imageName: "slider-item-desktop_31.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "slider-item-desktop_34.webp",
        minBreakpoint: "320px",
      },
    ],
  },
]

import * as React from "react"
import Seo from "../components/seo2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import Layout from "../components/layout2023"
import PredictionEngineV2 from "../componentsv2/prediction-enginev2/prediction-enginev2"
const IndexPage = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo title="Prediction Engine" />
    <PredictionEngineV2 />
    <FooterV2 version="v2" />
  </Layout>
)

export default IndexPage
